import _ from 'lodash';
import { colors, responsiveFontSizes, createTheme as createMuiTheme } from '@mui/material';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

const baseOptions = {
  direction: 'ltr',
  typography,

  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },
    },
    breakpoints: {
      styleOverrides: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1600,
          xl: 1700,
        },
      },
    },
  },
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#f6f8fb',
        paper: colors.common.white,
        grey: '#ccc',
      },
      primary: {
        main: '#e0e0e0',
      },
      secondary: {
        main: '#9b5f17',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        main: '#e0e0e0',
        dark: '#000',
      },
      success: {
        main: '#4caf50',
      },
      warning: {
        main: '#ff9800',
      },
      button: {
        main: '#0d151c',
        clickable: 'rgba(211, 211, 211, 0.25)',
        hover: 'rgba(173, 216, 230, 0.5)',
      },
      tab: {
        default: colors.blueGrey[600],
        active: '#9b5f17',
        inactive: '#efeeea',
        inactiveBackground: '#e0e0e0',
        hover: '#edeef1',
        indicator: colors.blueGrey[600],
      },
      header: {
        dark: '#0d151c',
      },
      cancel: colors.red[800],
      icons: 'MediumPurple',
      row: {
        selected: '#e5e5e5',
        selectedHover: '#dcdcdc',
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#1f3042',
        dark: '#131e29',
        paper: '#1f3042',
        grey: '#ccc',
      },
      primary: {
        main: '#9b5f17',
      },
      secondary: {
        main: '#9b5f17',
      },
      text: {
        primary: '#efeeea',
        secondary: '#87a7c7',
        main: '#efeeea',
      },
      cancel: colors.red[800],
      icons: 'MediumPurple',
      success: {
        main: '#4caf50',
      },
      warning: {
        main: '#ff9800',
      },
      button: {
        main: '#9b5f17',
        clickable: 'rgba(211, 211, 211, 0.25)',
        hover: 'rgba(173, 216, 230, 0.5)',
      },
      tab: {
        default: '#87a7c1',
        active: '#9b5f17',
        inactiveBackground: 'rgba(255, 255, 255, 0.04)',
        inactiveText: '#efeeea',
        hover: 'rgba(255, 255, 255, 0.12)',
        indicator: '#9b5f17',
      },
      row: {
        selected: '#383c3c',
        selectedHover: '#403c3c',
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(_.merge(themeOptions, baseOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
