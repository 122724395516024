/* eslint-disable no-undef */
import { API_ROOT } from './api-config';
import { createUrlFilterFromObject } from 'src/utilities/Utilities';
import { saveFreshAccessTokenInLocalStorage } from 'src/utilities/msalUtilities';
import { getCurrentDateTimeString } from 'src/utilities/DateUtilities';

const getAccessToken = async () => {
  await saveFreshAccessTokenInLocalStorage();
  return localStorage.getItem('auth');
};

const getResponse = async (response) => {
  let responseBody = response.body;

  if (responseBody) {
    if (response.headers.get('content-type') === 'application/json; charset=utf-8') {
      responseBody = await response.json();
    } else if (response.headers.get('content-type') === 'application/vnd.openxmlformats-officedocument.spreadsheetml.xlsx') {
      responseBody = await response.blob();
    } else {
      responseBody = await response.text();
    }
  }

  return {
    success: response.ok,
    body: responseBody,
    response: response,
    errorMessage: !response.ok ? responseBody || response.statusText : response.statusText,
  };
};

const executePostRequest = async (url, entity) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(url, {
      method: 'POST',
      headers,
      body: entity ? JSON.stringify(entity) : null,
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response.statusText);
        }

        return getResponse(response);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return null;
};

const executePostRequestWithFormData = async (url, formData) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: '*/*',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(url, {
      method: 'POST',
      headers,
      body: formData,
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response.statusText);
        }

        return getResponse(response);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return null;
};

const executeAnonymousPostRequest = async (url, entity) => {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(entity),
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return getResponse(response);
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
};

const executeAnonymousPostRequestWithFormData = async (url, formData) => {
  const headers = new Headers({
    Accept: '*/*',
  });

  return fetch(url, {
    method: 'POST',
    headers,
    body: formData,
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return getResponse(response);
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
};

const executeAnonymousPatchRequest = async (url, entity) => {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json-patch+json',
  });

  return fetch(url, {
    method: 'PATCH',
    headers,
    body: entity ? JSON.stringify(entity) : null,
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return getResponse(response);
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
};

const executeGetRequest = async (url, sort = '') => {
  const accessToken = await getAccessToken();
  const getUrl = sort ? `${url}&sort=${sort}` : url;

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(getUrl, {
      method: 'GET',
      headers,
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response.statusText);
        }

        return getResponse(response);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return null;
};

const executeAnonymousGetRequest = async (url) => {
  const headers = new Headers({
    Accept: 'application/json',
  });

  return fetch(url, {
    method: 'GET',
    headers,
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return getResponse(response);
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
};

const executeDeleteRequest = async (url, entity) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(url, {
      method: 'DELETE',
      headers,
      body: entity ? JSON.stringify(entity) : null,
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response.statusText);
        }

        return getResponse(response);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return false;
};

const executeAnonymousDeleteRequest = async (url, entity) => {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  return fetch(url, {
    method: 'DELETE',
    headers,
    body: entity ? JSON.stringify(entity) : null,
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return getResponse(response);
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
};

const executePutRequest = async (url, entity) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(url, {
      method: 'PUT',
      headers,
      body: entity ? JSON.stringify(entity) : null,
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response.statusText);
        }

        return getResponse(response);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return false;
};

const executeAnonymousPutRequest = async (url, entity) => {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  return fetch(url, {
    method: 'PUT',
    headers,
    body: entity ? JSON.stringify(entity) : null,
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return getResponse(response);
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
};

const executePatchRequest = async (url, entity) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json-patch+json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(url, {
      method: 'PATCH',
      headers,
      body: entity ? JSON.stringify(entity) : null,
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response.statusText);
        }

        return getResponse(response);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return false;
};

const executeAnonymousGetRequestForBlob = async (url, fileName) => {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  return fetch(url, {
    method: 'GET',
    headers,
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return getResponse(response);
    })
    .then((response) => {
      const element = document.createElement('a');
      element.href = URL.createObjectURL(response.body);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();

      return response;
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });

  return null;
};

const executeGetRequestForBlob = async (url, fileName) => {
  const accessToken = await getAccessToken();

  if (accessToken) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    return fetch(url, {
      method: 'GET',
      headers,
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response.statusText);
        }

        return getResponse(response);
      })
      .then((response) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(response.body);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();

        return response;
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  return null;
};

const executePostRequestForBlob = async (url, fileName, entity) => {
  const accessToken = await getAccessToken();
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });

  return fetch(url, {
    method: 'POST',
    headers,
    body: entity ? JSON.stringify(entity) : '',
    mode: 'cors',
  })
    .then((response) => {
      if (!response.ok) {
        console.error(response.statusText);
      }

      return getResponse(response);
    })
    .then((response) => {
      if (response.success) {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(response.body);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
      }

      return response;
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
};

export const getAmazonCredentials = async () => {
  const url = `${API_ROOT}/api/Credentials/GetAmazonCredentialsWithOtpCodes`;

  return await executeGetRequest(url);
};

export const getChildClients = async (filters) => {
  let url;

  if (filters) {
    url = `${API_ROOT}/api/childClients?${createUrlFilterFromObject(filters)}`;
  } else {
    url = `${API_ROOT}/api/childClients`;
  }

  return await executeGetRequest(url, 'name');
};

export const getChildClientsIncludingInactive = async (filters) => {
  let url;

  if (filters) {
    url = `${API_ROOT}/api/ChildClients/GetAllIncludingInactive?${createUrlFilterFromObject(filters)}`;
  } else {
    url = `${API_ROOT}/api/ChildClients/GetAllIncludingInactive`;
  }

  return await executeGetRequest(url, 'name');
};

export const getChildClientById = async (id) => {
  if (![0, 1, undefined].includes(id)) {
    const url = `${API_ROOT}/api/childClients/${id}`;

    return await executeGetRequest(url);
  }

  return {};
};

export const getParentClients = async () => {
  const url = `${API_ROOT}/api/parentClients?limit=0`;
  return await executeGetRequest(url, 'name');
};

export const getAllParentClientsWithoutAuthorization = async () => {
  const url = `${API_ROOT}/api/parentClients/GetAllWithoutAuthorization?limit=0`;

  return await executeGetRequest(url, 'name');
};

export const getActiveAndInactiveParentClientsWithoutAuthorization = async () => {
  const url = `${API_ROOT}/api/parentClients/GetActiveAndInactiveWithoutAuthorization?limit=0`;

  return await executeGetRequest(url, 'name');
};

export const updateProduct = async (product) => {
  const url = `${API_ROOT}/api/Products/${product.id}`;

  return await executePutRequest(url, product);
};

export const getProductsByParentClient = async (parentClientId, filters) => {
  let url;

  if (filters) {
    url = `${API_ROOT}/api/ParentClients/${parentClientId}/Products?${createUrlFilterFromObject(filters)}`;
  } else {
    url = `${API_ROOT}/api/ParentClients/${parentClientId}/Products`;
  }

  return await executeGetRequest(url);
};

export const bulkInitiateProducts = async (
  user,
  parentClientId,
  retailerId,
  credentialId,
  templateFile,
  emailMessage,
  validEmails,
  dueDate,
  attachments
) => {
  const url = `${API_ROOT}/api/Products/BulkInitiateProducts?userName=${user.displayName}`;

  const formData = new FormData();
  formData.append('parentClientId', parentClientId);
  formData.append('retailerId', retailerId);
  formData.append('credentialId', credentialId);
  formData.append('templateFile', templateFile);
  formData.append('emailMessage', emailMessage);
  formData.append('CCEmails', JSON.stringify(validEmails));
  formData.append('dueDate', dueDate);
  for (let attachment of attachments || []) {
    formData.append('attachments', attachment);
  }

  return executePostRequestWithFormData(url, formData);
};

export const bulkDeleteChannelProduct = async (channelProductIds) => {
  const url = `${API_ROOT}/api/ChannelProducts/BulkDelete`;

  return executeDeleteRequest(url, channelProductIds);
};

export const bulkUndeleteChannelProduct = async (channelProductIds) => {
  const url = `${API_ROOT}/api/ChannelProducts/BulkUndelete`;

  return executePutRequest(url, channelProductIds);
};

export const getChannelProductsByProductId = async (productId) => {
  const url = `${API_ROOT}/api/Products/${productId}/ChannelProducts/`;

  return await executeGetRequest(url);
};

export const getChannelProductById = async (channelProductId) => {
  const url = `${API_ROOT}/api/ChannelProducts/${channelProductId}`;

  return await executeGetRequest(url);
};

export const createChannelProduct = async (channelProduct) => {
  const url = `${API_ROOT}/api/ChannelProducts`;

  return executePostRequest(url, channelProduct);
};

export const updateChannelProductRetailerId = async (editedValue, channelProduct) => {
  const url = `${API_ROOT}/api/ChannelProducts/UpdateChannelProductRetailerId?editedValue=${editedValue}`;

  return executePutRequest(url, channelProduct);
};

export const updateChannelProductCredential = async (credential, channelProductId) => {
  const url = `${API_ROOT}/api/ChannelProducts/UpdateChannelProductCredential?channelProductId=${channelProductId}`;

  return executePutRequest(url, credential);
};

export const updateChannelProduct = async (channelProduct) => {
  const url = `${API_ROOT}/api/ChannelProducts/${channelProduct.id}`;

  return executePutRequest(url, channelProduct);
};

export const clientGetChannelProduct = async (hashReference, channelProductId) => {
  const url = `${API_ROOT}/api/clientcontent/channelProducts/${channelProductId}?hashReference=${hashReference}`;

  return executeAnonymousGetRequest(url);
};

export const getChannelProductsForParentClientAndRetailer = async (hashReference, retailerId, paginationConfig) => {
  let url =
    `${API_ROOT}/api/clientContent/channelProducts?` +
    `hashReference=${hashReference}&` +
    `retailerId=${retailerId}&` +
    `offset=${paginationConfig.offset}&` +
    `limit=${paginationConfig.limit}&` +
    `sort=${paginationConfig.sortBy}&` +
    `sortDirection=${paginationConfig.sortOrder}`;

  if (paginationConfig.searchValue) {
    url = url + `&searchValue=${paginationConfig.searchValue}`;
  }

  return await executeAnonymousGetRequest(url);
};

export const getAllChannelProductsForParentClient = async (hashReference) => {
  let url = `${API_ROOT}/api/ClientContent/GetChannelProductsByParentClientHashReference?hashReference=${hashReference}`;

  return await executeAnonymousGetRequest(url);
};

export const getProductsByParentClientHashReference = async (hashReference) => {
  let url = `${API_ROOT}/api/clientContent/products?hashReference=${hashReference}&limit=0`;

  return await executeAnonymousGetRequest(url);
};

export const getParentClientByHashReference = async (hashReference) => {
  let url = `${API_ROOT}/api/clientContent/ParentClients?hashReference=${hashReference}`;

  return executeAnonymousGetRequest(url);
};

export const enqueueUploadToWalmart = async (contentDraftIds) => {
  const url = `${API_ROOT}/api/ContentDrafts/EnqueueUploadToWalmart`;

  const formData = new FormData();
  formData.append('contentDraftIds', JSON.stringify(contentDraftIds));

  return executePostRequestWithFormData(url, formData);
};

export const publishBasicContentToAmazon = async (contentDraftId) => {
  const url = `${API_ROOT}/api/ContentDrafts/PublishBasicContentToAmazon?contentDraftId=${contentDraftId}`;

  return executePostRequest(url);
};

export const saveContentDraft = async (isClientView, updatedContentDraft, hashReference) => {
  if (isClientView) {
    return executeAnonymousPutRequest(
      `${API_ROOT}/api/clientcontent/content/${updatedContentDraft.contentId}/contentdrafts/${updatedContentDraft.id}?hashReference=${hashReference}`,
      updatedContentDraft
    );
  } else {
    return executePutRequest(
      `${API_ROOT}/api/content/${updatedContentDraft.contentId}/contentdrafts/${updatedContentDraft.id}`,
      updatedContentDraft
    );
  }
};

export const getRetailers = async () => {
  const url = `${API_ROOT}/api/Retailers?limit=0`;

  return await executeGetRequest(url);
};

export const getRetailerById = async (retailerId) => {
  const url = `${API_ROOT}/api/Retailers/${retailerId}`;

  return await executeGetRequest(url);
};

export const getAllRetailersWithoutAuthorization = async () => {
  const url = `${API_ROOT}/api/Retailers/GetAllWithoutAuthorization?limit=0`;

  return await executeGetRequest(url);
};

export const getRetailersByParentClient = async (parentClientId) => {
  const url = `${API_ROOT}/api/ParentClients/${parentClientId}/Retailers`;

  return await executeGetRequest(url);
};

export const getCredentialByChildClientId = async (childClientId) => {
  const url = `${API_ROOT}/api/ChildClients/${childClientId}/credentials`;

  return await executeGetRequest(url);
};

export const updateCredential = async (credentialId, credential) => {
  const url = `${API_ROOT}/api/credentials/${credentialId}`;

  return executePutRequest(url, credential);
};

export const getGoogleAdsKeywords = async (searchTerm) => {
  const searchTerms = searchTerm.split('|');

  const queryParameters = searchTerms.map((term) => `searchTerms=${encodeURIComponent(term.trim())}`).join('&');

  const url = `${API_ROOT}/api/GoogleAds?${queryParameters}`;

  return await executeGetRequest(url);
};

export const getJungleScoutKeywords = async (searchTerm) => {
  const url = `${API_ROOT}/api/JungleScout?keyword=${searchTerm}`;

  return await executeGetRequest(url);
};

export const getProductKeywordsByCategoryAndSource = async (products, category, source) => {
  const productIds = products.map((product) => product.id);

  const keywordSource = source != null ? source : '';

  const url = `${API_ROOT}/api/ProductKeywords/GetKeywordsOfMultipleProductsByCategoryAndSource?productId=${productIds.join(
    ','
  )}&keywordCategory=${category}&keywordSource=${keywordSource}`;

  return await executeGetRequest(url);
};

export const postProductKeywordsByCategory = async (keywords, productIds, category, source) => {
  const url = `${API_ROOT}/api/ProductKeywords/BulkCreate?keywordCategory=${category}`;

  const payload = {
    source: source,
    productIds: productIds,
    keywords: keywords,
  };

  return await executePostRequest(url, payload);
};

export const getKeywordingStatesByProductId = async (productId) => {
  const url = `${API_ROOT}/api/Products/${productId}/KeywordCategoryStates`;

  return await executeGetRequest(url);
};

export const importKeywords = (file) => {
  const url = `${API_ROOT}/api/ImportedKeywords/ImportKeywords`;

  const formData = new FormData();
  formData.append('file', file);

  return executePostRequestWithFormData(url, formData);
};

export const sendChannelsToCopywriting = async (productId) => {
  const url = `${API_ROOT}/api/Products/${productId}/SendChannelProductsToCopywriting`;

  return await executePutRequest(url);
};

export const getFilteredChannelProducts = async (parentClientId, retailerId, paginationConfig) => {
  const contentStates = paginationConfig.contentStates;
  const contentUploadStatus = paginationConfig.contentUploadStatus;
  const searchValue = paginationConfig.searchValue;
  const limit = paginationConfig.limit;
  const offset = paginationConfig.offset;
  const sortValue = paginationConfig.sortBy;
  const sortOrder = paginationConfig.sortOrder;

  let url = `${API_ROOT}/api/ChannelProducts/GetFilteredChannelProductsByContent?limit=${limit}&offset=${offset}&sortDirection=${sortOrder}`;

  if (parentClientId) {
    url = url + `&parentClientId=${parentClientId}`;
  }

  if (searchValue) {
    url = url + `&searchValue=${searchValue}`;
  }

  if (retailerId) {
    url = url + `&retailerId=${retailerId}`;
  }

  if (contentStates) {
    url = url + `&contentStates=${contentStates}`;
  }

  if (contentUploadStatus) {
    url = url + `&contentUploadStatus=${contentUploadStatus}`;
  }

  return await executeGetRequest(url, sortValue);
};

export const patchChannelProduct = async (channelProductId, value, path) => {
  const url = `${API_ROOT}/api/ChannelProducts/${channelProductId}`;
  const payload = [
    {
      value: value,
      path: path,
      op: 'replace',
    },
  ];

  return executePatchRequest(url, payload);
};

export const moveState = async (contentId, trigger, isClientView, hashReference) => {
  if (isClientView) {
    const url = `${API_ROOT}/api/clientcontent/content/${contentId}/MoveState?trigger=${trigger}&hashReference=${hashReference}`;

    return executeAnonymousPutRequest(url);
  } else {
    const url = `${API_ROOT}/api/content/${contentId}/MoveState?trigger=${trigger}`;

    return executePutRequest(url);
  }
};

export const getCredentialByParentClientId = async (parentClientId) => {
  const url = `${API_ROOT}/api/ParentClients/${parentClientId}/credentials`;

  return await executeGetRequest(url);
};

export const createProduct = async (product) => {
  const url = `${API_ROOT}/api/Products`;

  return executePostRequest(url, product);
};

export const createPlaceholder = async () => {
  const url = `${API_ROOT}/api/Products/GetPlaceholderGtin14`;

  return executeGetRequest(url);
};

export const validateGtin14 = async (gtin14) => {
  const url = `${API_ROOT}/api/Products/ValidateGtin?gtin14=${gtin14}`;

  return executeGetRequest(url, gtin14);
};

export const mergePlaceholderToValidProduct = async (placeholderId, validProductId) => {
  const url = `${API_ROOT}/api/Products/MergeAndDelete?productIdToMerge=${placeholderId}&baseProductId=${validProductId}`;

  return executePutRequest(url);
};

export const moveChannelToValidProduct = async (channelProductId, productId) => {
  const url = `${API_ROOT}/api/ChannelProducts/MoveChannelProduct?originalChannelProductId=${channelProductId}&baseProductId=${productId}`;

  return executePutRequest(url);
};

export const getSearchTerms = async (childClientId) => {
  const url = `${API_ROOT}/api/ChildClients/${childClientId}/SearchTerms`;

  return executeGetRequest(url);
};

export const addTrackedKeyword = async (childClientId, term) => {
  const url = `${API_ROOT}/api/ManagedKeywords/CreateTrackedKeyword`;

  return executePostRequest(url, {
    childClientId: childClientId,
    keyword: term,
    keywordType: 'Tracked',
    createdAt: new Date().toISOString(),
  });
};

export const getAllTrackedKeywords = async (childClientId) => {
  const url = `${API_ROOT}/api/ManagedKeywords/ChildClient/${childClientId}/AllTrackedKeywords`;

  return executeGetRequest(url);
};

export const getTrackedKeywords = async (childClientId, paginationSettings, scrapeFrequencyFilterValue) => {
  const url =
    `${API_ROOT}/api/ManagedKeywords/ChildClient/${childClientId}/TrackedKeywords?` +
    `offset=${paginationSettings.offset}&` +
    `limit=${paginationSettings.limit}&` +
    `sort=${paginationSettings.sort}&` +
    `sortDirection=${paginationSettings.sortDirection}&` +
    `scrapeFrequencyFilterValue=${scrapeFrequencyFilterValue}`;

  return executeGetRequest(url);
};

export const updateTrackedKeywordScrapeFrequency = async (trackedKeywordId, newScrapeFrequency) => {
  const url = `${API_ROOT}/api/ManagedKeywords/${trackedKeywordId}`;

  let patchDocument = [{ op: 'replace', path: '/scrapesPerDay', value: newScrapeFrequency }];

  return executePatchRequest(url, patchDocument);
};

export const deleteTrackedKeyword = async (trackedKeywordId) => {
  const url = `${API_ROOT}/api/ManagedKeywords`;

  return executeDeleteRequest(url, trackedKeywordId);
};

export const deleteSearchTerm = async (childClientId, searchTermId) => {
  const url = `${API_ROOT}/api/ChildClients/${childClientId}/SearchTerms/${searchTermId}`;

  return executeDeleteRequest(url);
};

export const addSearchTerm = async (childClientId, term) => {
  const url = `${API_ROOT}/api/SearchTerms`;

  return executePostRequest(url, {
    term: term,
    childClientIds: [childClientId],
  });
};

export const getAllComments = async (isClientView, contentId, contentDraftId, hashReference) => {
  if (!isClientView) {
    const url = `${API_ROOT}/api/Content/${contentId}/Comments`;

    return executeGetRequest(url);
  } else {
    const url = `${API_ROOT}/api/clientcontent/ContentDrafts/${contentDraftId}/DraftComments?hashReference=${hashReference}`;

    return executeAnonymousGetRequest(url);
  }
};

export const createCommentForContentDraft = async (isClientView, contentDraftId, hashReference, comment) => {
  if (!isClientView) {
    const url = `${API_ROOT}/api/ContentDrafts/${contentDraftId}/DraftComments`;

    return executePostRequest(url, comment);
  } else {
    const url = `${API_ROOT}/api/clientcontent/ContentDrafts/${contentDraftId}/DraftComments?hashReference=${hashReference}`;

    return executeAnonymousPostRequest(url, comment);
  }
};

export const deleteCommentForContentDraft = async (isClientView, contentDraftId, draftCommentId, hashReference, comment) => {
  if (!isClientView) {
    const url = `${API_ROOT}/api/ContentDrafts/${contentDraftId}/DraftComments/${draftCommentId}`;

    return executeDeleteRequest(url, comment);
  } else {
    const url = `${API_ROOT}/api/clientcontent/ContentDrafts/${contentDraftId}/DraftComments/${draftCommentId}?hashReference=${hashReference}`;

    return await executeAnonymousDeleteRequest(url, comment);
  }
};

export const editClientComment = async (isClientView, contentDraftId, hashReference, comment) => {
  if (!isClientView) {
    const url = `${API_ROOT}/api/ContentDrafts/${contentDraftId}/DraftComments/${comment.id}`;

    return executePutRequest(url, comment);
  } else {
    const url = `${API_ROOT}/api/clientcontent/ContentDrafts/${contentDraftId}/DraftComments?hashReference=${hashReference}`;

    return executeAnonymousPutRequest(url, comment);
  }
};

export const getContentDraftByContentId = async (contentId, draftType) => {
  const url = `${API_ROOT}/api/Contents/${contentId}/ContentDrafts?draftType=${draftType}`;

  return executeGetRequest(url);
};

export const getMediaByChannelProductId = async (channelProductId) => {
  const url = `${API_ROOT}/api/ChannelProducts/${channelProductId}/Media`;

  return executeGetRequest(url);
};

export const getMediaDraftsById = async (id) => {
  let url = `${API_ROOT}/api/MediaDrafts/${id}`;

  return executeGetRequest(url);
};

export const getActiveMediaDraftForDraftType = async (mediaId, draftType) => {
  let url = `${API_ROOT}/api/Media/${mediaId}/ActiveMediaDraft/${draftType}`;

  return executeGetRequest(url);
};

export const createEnhancedContentDraft = async (type, draft) => {
  let url = `${API_ROOT}/api/MediaDrafts/${type}`;

  return executePostRequest(url, draft);
};

export const patchEnhancedContentDraft = async (type, draft) => {
  let url = `${API_ROOT}/api/MediaDrafts/${type}/${draft.id}`;

  let patchDocument = [
    { op: 'replace', path: '/name', value: draft.name },
    { op: 'replace', path: '/draft', value: draft.draft },
  ];

  return executePatchRequest(url, patchDocument);
};

export const publishEnhancedContentDraft = async (draftId) => {
  let url = `${API_ROOT}/api/MediaDrafts/PublishMediaDrafts`;

  return executePostRequest(url, [draftId]);
};

export const publishEnhancedContentDrafts = async (draftIds) => {
  let url = `${API_ROOT}/api/MediaDrafts/PublishMediaDrafts`;

  return executePostRequest(url, draftIds);
};

export const createFileAsset = async (fileAssetCreateModel) => {
  let url = `${API_ROOT}/api/FileAssets`;

  const formData = new FormData();

  Object.entries(fileAssetCreateModel).forEach((entry) => {
    if (entry[1] !== null) formData.append(entry[0], entry[1]);
  });

  return executePostRequestWithFormData(url, formData);
};

export const createFileAssetByHashReference = async (hashReference, fileAssetCreateModel) => {
  let url = `${API_ROOT}/api/clientContent/fileAssets?hashReference=${hashReference}`;

  const formData = new FormData();

  Object.entries(fileAssetCreateModel).forEach((entry) => {
    if (entry[1] !== null) formData.append(entry[0], entry[1]);
  });

  return executeAnonymousPostRequestWithFormData(url, formData);
};

export const getFileAssetsByParentClientHashReference = async (hashReference) => {
  let url = `${API_ROOT}/api/clientContent/fileAssets?hashReference=${hashReference}`;

  return executeAnonymousGetRequest(url);
};

export const getFileAssetsByParentClientId = async (parentClientId) => {
  let url = `${API_ROOT}/api/FileAssets/parentClient/${parentClientId}`;

  return executeGetRequest(url);
};

export const clientBulkDeleteFileAssets = async (hashReference, fileAssetIds) => {
  let url = `${API_ROOT}/api/clientContent/fileAssets/BulkDelete?hashReference=${hashReference}`;

  return executeAnonymousDeleteRequest(url, fileAssetIds);
};

export const bulkUndeleteClientFileAssets = async (hashReference, fileAssetIds) => {
  let url = `${API_ROOT}/api/clientContent/FileAssets/bulkUndelete?hashReference=${hashReference}`;

  return executeAnonymousPutRequest(url, fileAssetIds);
};

export const bulkMoveClientFileAssets = async (hashReference, fileAssetCreateModel) => {
  let url = `${API_ROOT}/api/clientContent/FileAssets/bulkMove?hashReference=${hashReference}`;

  return executeAnonymousPatchRequest(url, fileAssetCreateModel);
};

export const renameClientFolder = async (hashReference, fileAssetRenameFolderModel) => {
  let url = `${API_ROOT}/api/clientContent/FileAssets/renameFolder?hashReference=${hashReference}`;

  return executeAnonymousPatchRequest(url, fileAssetRenameFolderModel);
};

export const updateClientAsset = async (hashReference, fileAssetUpdateModel) => {
  let url = `${API_ROOT}/api/clientContent/FileAssets/UpdateFileAsset?hashReference=${hashReference}`;

  return executeAnonymousPatchRequest(url, fileAssetUpdateModel);
};

export const addExternalUrlToFileAsset = async (fileAssetAddExternalUrlModel) => {
  let url = `${API_ROOT}/api/FileAssets/AddExternalUrlToFileAsset`;

  return executePatchRequest(url, fileAssetAddExternalUrlModel);
};

export const bulkDeleteFileAssets = async (fileAssetIds) => {
  let url = `${API_ROOT}/api/FileAssets/BulkDelete`;

  return executeDeleteRequest(url, fileAssetIds);
};

export const bulkUndeleteFileAssets = async (fileAssetIds) => {
  let url = `${API_ROOT}/api/FileAssets/BulkUndelete`;

  return executePutRequest(url, fileAssetIds);
};

export const renameFolder = async (fileAssetRenameFolderModel) => {
  let url = `${API_ROOT}/api/FileAssets/rename-folder`;

  return executePatchRequest(url, fileAssetRenameFolderModel);
};

export const moveAssets = async (fileAssetMoveModel) => {
  let url = `${API_ROOT}/api/FileAssets/move-assets`;

  return executePatchRequest(url, fileAssetMoveModel);
};

export const updateAsset = async (fileAssetUpdateModel) => {
  let url = `${API_ROOT}/api/FileAssets`;

  return executePatchRequest(url, fileAssetUpdateModel);
};

export const deleteFolder = async (fileAssetDeleteModel) => {
  let url = `${API_ROOT}/api/FileAssets/delete-folder`;

  return executeDeleteRequest(url, fileAssetDeleteModel);
};

export const createMedia = async (channelProductId) => {
  let url = `${API_ROOT}/api/Media`;

  let response = await executePostRequest(url, { channelProductId: channelProductId });

  return response.body;
};

export const fetchPublishErrorFeed = async (contentPublishFeedId) => {
  let url = `${API_ROOT}/api/FeedResponses/${contentPublishFeedId}`;

  return executeGetRequest(url);
};

export const getFeatureFlags = async () => {
  let url = `${API_ROOT}/api/FeatureFlags`;

  return executeAnonymousGetRequest(url);
};

export const getCloudinaryCredentials = async () => {
  let url = `${API_ROOT}/api/CloudinaryCredentials`;

  return executeGetRequest(url);
};

export const getUserAlerts = async () => {
  let url = `${API_ROOT}/api/Alerts`;

  return executeGetRequest(url);
};

export const archiveAlert = async (alertId) => {
  let url = `${API_ROOT}/api/Alerts/${alertId}`;

  return executePutRequest(url);
};

export const unarchiveAlert = async (alertId) => {
  let url = `${API_ROOT}/api/Alerts/${alertId}`;

  return executeDeleteRequest(url);
};

export const getUserDirectReports = async (includeUser = false) => {
  let url = `${API_ROOT}/api/MicrosoftGraph/GetUserDirectReports`;

  if (includeUser) {
    url += '?includeUser=true';
  }

  return executeGetRequest(url);
};

export const getUserExternalUsers = async () => {
  let url = `${API_ROOT}/api/MicrosoftGraph/GetUserExternalUsers`;

  return executeGetRequest(url);
};

export const getExternalUsersByParentClient = async (parentClientId) => {
  let url = `${API_ROOT}/api/MicrosoftGraph/GetExternalUsersByParentClient?parentClientId=${parentClientId}`;

  return executeGetRequest(url);
};

export const getPaginatedChildClientsByUser = async (selectedUser, paginationConfig) => {
  let url = `${API_ROOT}/api/MicrosoftGraph/GetPaginatedUserAuthorizedChildClients?userObjectId=${
    selectedUser.id
  }&${getPaginatedUrlQuery(paginationConfig)}`;

  return executeGetRequest(url);
};

export const getChildClientsByUser = async () => {
  let url = `${API_ROOT}/api/MicrosoftGraph/GetUserAuthorizedChildClients`;

  return executeGetRequest(url);
};

export const getAllChildClientsAndAuthorizedPlaceholders = async () => {
  const url = `${API_ROOT}/api/MicrosoftGraph/GetAllChildClientsAndUserAuthorizedPlaceholders`;

  return await executeGetRequest(url);
};

const getPaginatedUrlQuery = (paginationConfig) => {
  if (paginationConfig?.offset === undefined) {
    paginationConfig.offset = 0;
  }

  if (paginationConfig?.limit === undefined) {
    paginationConfig.limit = 25;
  }

  if (paginationConfig?.sort === undefined) {
    paginationConfig.sort = 'name';
  }

  if (paginationConfig?.sortDirection === undefined) {
    paginationConfig.sortDirection = 'ascending';
  }

  return (
    `offset=${paginationConfig.offset}&` + `limit=${paginationConfig.limit}&` + `sortDirection=${paginationConfig.sortDirection}`
  );
};

export const getInternalUsersByChildClient = async (childClientId) => {
  let url = `${API_ROOT}/api/MicrosoftGraph/GetInternalUsersByChildClient?childClientId=${childClientId}`;

  return executeGetRequest(url);
};

export const getPaginatedInternalUsersByChildClient = async (childClientId, paginationConfig) => {
  let url = `${API_ROOT}/api/MicrosoftGraph/GetPaginatedInternalUsersByChildClient?childClientId=${childClientId}
    &${getPaginatedUrlQuery(paginationConfig)}`;

  return executeGetRequest(url);
};

export const getPaginatedExternalUsersByChildClient = async (childClientId, paginationConfig) => {
  let url = `${API_ROOT}/api/MicrosoftGraph/GetPaginatedExternalUsersByChildClient?childClientId=${childClientId}
    &${getPaginatedUrlQuery(paginationConfig)}`;

  return executeGetRequest(url);
};

export const archiveAlertForAllUsers = async (alertId) => {
  let url = `${API_ROOT}/api/Alerts/${alertId}/archiveAlertForAllUsers`;

  return executePutRequest(url);
};

export const updateRetailer = async (id, retailer) => {
  const url = `${API_ROOT}/api/Retailers/${id}`;

  return executePutRequest(url, retailer);
};

export const getSnowflakeTables = async (shouldGetUserFacingTablesOnly) => {
  const url = `${API_ROOT}/api/SnowflakeTableMetadata?shouldGetUserFacingTablesOnly=${shouldGetUserFacingTablesOnly}`;

  return await executeGetRequest(url);
};

export const getSnowflakeTableMetadataById = async (snowflakeTableMetadataId) => {
  const url = `${API_ROOT}/api/SnowflakeTableMetadata/${snowflakeTableMetadataId}`;

  return await executeGetRequest(url);
};

export const enqueueFileDownload = async (
  clientId,
  snowflakeTableMetaData,
  selectedRetailersString,
  isAllClientsAction = false
) => {
  const url = `${API_ROOT}/api/SnowflakeTableMetadata/${snowflakeTableMetaData.downloadEnqueueEndpoint}`;

  const formData = new FormData();

  formData.append('clientId', clientId);
  formData.append('file', null);
  formData.append('snowflakeTableMetadataId', snowflakeTableMetaData.id);
  formData.append('selectedRetailers', selectedRetailersString);
  formData.append('isAllClientsAction', isAllClientsAction);

  return executePostRequestWithFormData(url, formData);
};

export const enqueueSnowflakeTableFileUpload = async (clientId, snowflakeTableMetaData, file, isAllClientsAction = false) => {
  const url = `${API_ROOT}/api/SnowflakeTableMetadata/${snowflakeTableMetaData.uploadEnqueueEndpoint}`;

  const formData = new FormData();

  formData.append('clientId', clientId);
  formData.append('file', file);
  formData.append('snowflakeTableMetadataId', snowflakeTableMetaData.id);
  formData.append('isAllClientsAction', isAllClientsAction);

  return executePostRequestWithFormData(url, formData);
};

export const uploadForecastRoundtableFile = async (file, isLockPeriodOpen, selectedFiscalYear) => {
  const url = `${API_ROOT}/api/ForecastRoundtable/UploadFile`;

  const formData = new FormData();
  formData.append('file', file[0]);
  formData.append('isLockPeriodOpen', isLockPeriodOpen);
  formData.append('selectedFiscalYear', selectedFiscalYear.toString());

  return executePostRequestWithFormData(url, formData);
};

export const downloadForecastRoundtableFile = async (netsuiteIds, selectedFiscalYear) => {
  const url = `${API_ROOT}/api/ForecastRoundtable/DownloadFile`;

  const formData = new FormData();
  formData.append('netsuiteIds', JSON.stringify(netsuiteIds));
  formData.append('selectedFiscalYear', selectedFiscalYear.toString());

  return executePostRequestWithFormData(url, formData);
};

export const getLockedForecastRoundtableClients = async (netsuiteIds) => {
  const url = `${API_ROOT}/api/ForecastRoundtableFileActions/GetLockedForecastRoundtableClients`;

  const formData = new FormData();
  formData.append('netsuiteIds', JSON.stringify(netsuiteIds));

  return executePostRequestWithFormData(url, formData);
};

export const getForecastRoundtableClientsForUser = () => {
  const url = `${API_ROOT}/api/ForecastRoundtable/GetForecastRoundtableClientsForUser`;

  return executeGetRequest(url);
};

export const getRevenueNetsuiteClients = () => {
  const url = `${API_ROOT}/api/ClientGoalsTracker/GetRevenueNetsuiteClients`;

  return executeGetRequest(url);
};

export const getAllForemanNetsuiteMappingRows = (paginationSettings, showActiveRecords, showMappedRecords) => {
  let url =
    `${API_ROOT}/api/ForecastRoundtable/GetAllForemanNetsuiteMappingRows?` +
    `offset=${paginationSettings.offset}&` +
    `limit=${paginationSettings.limit}&` +
    `sort=${paginationSettings.sort}&` +
    `sortDirection=${paginationSettings.sortDirection}&` +
    `showActiveRecords=${showActiveRecords}&` +
    `showMappedRecords=${showMappedRecords}`;

  if (paginationSettings.searchValue) {
    url = url + `&searchValue=${paginationSettings.searchValue}`;
  }

  return executeGetRequest(url);
};

export const updateNetsuiteForemanMappingRow = (netsuiteId, parentClientId, retailerId, serviceType, includeInDownload) => {
  const url = `${API_ROOT}/api/ForecastRoundtable/UpdateNetsuiteForemanMappingRow`;

  const formData = new FormData();
  formData.append('netsuiteId', netsuiteId);
  formData.append('parentClientId', parentClientId);
  formData.append('retailerId', retailerId);
  formData.append('serviceType', serviceType);
  formData.append('includeInDownload', includeInDownload);

  return executePostRequestWithFormData(url, formData);
};

export const manageForecastRoundtableFiscalYearConfiguration = async (config, actionType) => {
  const url = `${API_ROOT}/api/ForecastRoundtable/FiscalYearConfiguration?actionType=${actionType}`;

  return executePostRequest(url, config);
};

export const initiateEmberNotificationConfiguration = async (options) => {
  const url = `${API_ROOT}/api/EmberToolEmailNotificationConfigurations/CreateNotificationStatus`;

  return executePostRequest(url, options);
};

export const getEmailNotificationStatus = async (snowflakeTableMetadataId, parentClientId) => {
  const url = `${API_ROOT}/api/EmberToolEmailNotificationConfigurations/GetNotificationStatus?snowflakeTableMetadataId=${snowflakeTableMetadataId}&parentClientId=${parentClientId}`;

  return executeGetRequest(url);
};

export const getAllNotificationStatusForUser = async (snowflakeTableMetadataId) => {
  const url = `${API_ROOT}/api/EmberToolEmailNotificationConfigurations/GetAllNotificationStatusForUser?snowflakeTableMetadataId=${snowflakeTableMetadataId}`;

  return executeGetRequest(url);
};

export const updateOptInStatus = async (snowflakeTableMetadataId, parentClientId, isOptedInToAttributionNotifications) => {
  const url = `${API_ROOT}/api/EmberToolEmailNotificationConfigurations/UpdateNotificationStatus?snowflakeTableMetadataId=${snowflakeTableMetadataId}&parentClientId=${parentClientId}&isOptedInToAttributionNotifications=${isOptedInToAttributionNotifications}`;

  return executePutRequest(url);
};

export const bulkCreateOrUpdateNotificationStatusForUser = async (
  snowflakeTableMetadataId,
  isOptedInToAttributionNotifications
) => {
  const url = `${API_ROOT}/api/EmberToolEmailNotificationConfigurations/BulkCreateOrUpdateNotificationStatusForUser?snowflakeTableMetadataId=${snowflakeTableMetadataId}&isOptedInToAttributionNotifications=${isOptedInToAttributionNotifications}`;

  return executePostRequest(url);
};

export const bulkCreateUserNotificationStatus = async (requestBody) => {
  const url = `${API_ROOT}/api/EmberToolEmailNotificationConfigurations/BulkCreateUserNotificationStatus`;

  return executePostRequest(url, requestBody);
};

export const addChildClientsToUsers = async (updateUserPermissionsModel) => {
  const url = `${API_ROOT}/api/MicrosoftGraph/AddChildClientsToUsers`;

  return executePostRequest(url, updateUserPermissionsModel);
};

export const removeChildClientsFromUsers = async (updateUserPermissionsModel) => {
  const url = `${API_ROOT}/api/MicrosoftGraph/RemoveChildClientsFromUsers`;

  return executeDeleteRequest(url, updateUserPermissionsModel);
};

export const getParentClientsByRetailerId = async (retailerId) => {
  const url = `${API_ROOT}/api/ParentClients/GetByRetailerId?retailerId=${retailerId}`;

  return executeGetRequest(url);
};

export const getLastUserActionInformation = async (parentClientId, snowflakeTableId) => {
  const url = `${API_ROOT}/api/SnowflakeTableEditorUserActions/GetLatestTableActivityByParentClient?parentClientId=${parentClientId}&snowflakeTableId=${snowflakeTableId}`;

  return executeGetRequest(url);
};

export const getLatestPBCSFileUserActionInfo = async () => {
  const url = `${API_ROOT}/api/PBCSFileUserActions/GetLatestPBCSFileUserActionInfo`;

  return executeGetRequest(url);
};

export const uploadPBCSFile = async (file) => {
  const url = `${API_ROOT}/api/Finance/UploadPBCSExportFile`;

  const formData = new FormData();

  formData.append('file', file);
  formData.append('fileLastModifiedDateTime', file.lastModifiedDate.toISOString());

  return executePostRequestWithFormData(url, formData);
};

export const exportContent = async (parentClient, retailer) => {
  const url =
    `${API_ROOT}/api/ChannelProducts/ExportContent` + `?parentClientId=${parentClient?.id}` + `&retailerId=${retailer?.id}`;

  const parentClientName = parentClient?.name?.trim().split(' ').join('_');
  const retailerName = retailer?.name?.trim().split(' ').join('_');

  return executePostRequestForBlob(url, `${parentClientName}_${retailerName}_Content_${getCurrentDateTimeString()}.xlsx`);
};

export const exportContentForChannelProducts = async (channelProductIds, parentClient) => {
  const joinedChannelProductIds = channelProductIds.join(',');

  const url =
    `${API_ROOT}/api/ChannelProducts/ExportContentForChannelProducts` +
    `?channelProductIds=${joinedChannelProductIds}` +
    `&parentClientId=${parentClient?.id}`;

  const parentClientName = parentClient?.name?.trim().split(' ').join('_');

  return executePostRequestForBlob(url, `${parentClientName}_Content_${getCurrentDateTimeString()}.xlsx`);
};

export const downloadBaseContentImportTemplate = async (parentClient, retailer) => {
  const url =
    `${API_ROOT}/api/ChildClients/DownloadBaseContentImportTemplate` +
    `?parentClientId=${parentClient.id}` +
    `&retailerId=${retailer.id}`;

  const parentClientName = parentClient?.name.trim().split(' ').join('_');
  const retailerName = retailer?.name.trim().split(' ').join('_');
  const fileName = `${parentClientName}_${retailerName}_Import_Template.xlsx`;

  return executeGetRequestForBlob(url, fileName);
};

export const uploadBaseContentImportFile = async (file, importActionType, parentClientId) => {
  const url = `${API_ROOT}/api/ChannelProducts/UploadBaseContentImportFile`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('importActionType', importActionType);
  formData.append('parentClientId', parentClientId);

  return await executePostRequestWithFormData(url, formData);
};

export const exportClientReviewContent = async (channelProducts, parentClient) => {
  const channelProductIds = channelProducts.map((channelProduct) => channelProduct.id);

  const url =
    `${API_ROOT}/api/ClientContent/ExportClientReviewContent` +
    `?channelProductIds=${channelProductIds}` +
    `&parentClientId=${parentClient?.id}`;

  const parentClientName = parentClient?.name?.trim().split(' ').join('_');
  const fileName = `${parentClientName}_Content_Review_${getCurrentDateTimeString()}.xlsx`;

  return executeAnonymousGetRequestForBlob(url, fileName);
};

export const importClientReviewContentFile = async (file, parentClientId, importActionType) => {
  const url = `${API_ROOT}/api/ClientContent/ImportClientReviewContentFile`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('importActionType', importActionType);
  formData.append('parentClientId', parentClientId);

  return await executeAnonymousPostRequestWithFormData(url, formData);
};

export const createExternalUser = async (externalUserForm, childClientId) => {
  const url = `${API_ROOT}/api/MicrosoftGraph/CreateExternalUser?childClientId=${childClientId}`;

  return executePostRequest(url, externalUserForm);
};

export const resendExternalUserPassword = async (userObjectId) => {
  let url = `${API_ROOT}/api/MicrosoftGraph/ResendExternalUserCredential?userObjectId=${userObjectId}`;

  return await executePostRequest(url, null);
};

export const getGroupedAndUngroupedChannelProductsByChildClientId = async (childClientId, searchValue = '') => {
  const url = `${API_ROOT}/api/ChildClients/${childClientId}/MediaGroups?searchValue=${searchValue}`;

  return await executeGetRequest(url);
};

export const createMediaGroup = async (options) => {
  const url = `${API_ROOT}/api/MediaGroups/CreateMediaGroup`;

  return await executePostRequest(url, options);
};

export const deleteMediaGroup = async (groupId) => {
  const url = `${API_ROOT}/api/MediaGroups/DeleteMediaGroup/${groupId}`;

  return await executePutRequest(url);
};

export const renameMediaGroup = async (groupId, options) => {
  const url = `${API_ROOT}/api/MediaGroups/${groupId}`;

  return await executePutRequest(url, options);
};

export const addChannelProductsToGroup = async (options) => {
  const url = `${API_ROOT}/api/MediaGroups/AddChannelProductsToExistingGroup`;

  return await executePostRequest(url, options);
};

export const removeChannelProductFromGroup = async (channelProduct) => {
  const url = `${API_ROOT}/api/ChannelProducts/${channelProduct.id}`;
  return await executePutRequest(url, channelProduct);
};

export const updateClientGoals = async (goalsPage, netsuiteId, childClientId) => {
  const url = `${API_ROOT}/api/ClientGoalsTracker/UpdateClientGoals?netsuiteId=${netsuiteId}&childClientId=${childClientId}`;

  return await executePostRequest(url, goalsPage);
};

export const GetClientGoalsTrackerPage = async (netsuiteId, fiscalYear) => {
  const url = `${API_ROOT}/api/ClientGoalsTracker/GetClientGoalsTrackerPage?netsuiteId=${netsuiteId}&fiscalYear=${fiscalYear}`;

  return executeGetRequest(url);
};

export const getLastActionForClientGoalsTracker = async (netsuiteId) => {
  const url = `${API_ROOT}/api/ClientGoalsTrackerActions/GetLastAction?netsuiteId=${netsuiteId}`;

  return executeGetRequest(url);
};

export const getRevenueClientsMappings = async () => {
  const url = `${API_ROOT}/api/ClientGoalsTracker/GetRevenueClientsMappings`;

  return await executeGetRequest(url);
};

export const getUserSettings = async () => {
  const url = `${API_ROOT}/api/UserSettings/GetByUser`;

  return await executeGetRequest(url);
};

export const initializeUserSettings = async () => {
  const url = `${API_ROOT}/api/UserSettings/InitializeForUser`;

  return await executePostRequest(url, null);
};

export const updateUserSettings = async (updatedSettings) => {
  const url = `${API_ROOT}/api/UserSettings/${updatedSettings.id}`;

  return await executePutRequest(url, updatedSettings);
};

export const getFiscalYearConfigurations = async () => {
  const url = `${API_ROOT}/api/ForecastRoundtable/FiscalYearConfigurations`;

  return await executeGetRequest(url);
};

export const getDisplayableFiscalYearConfigurations = async () => {
  const url = `${API_ROOT}/api/ForecastRoundtable/DisplayableFiscalYearConfigurations`;

  return await executeGetRequest(url);
};
