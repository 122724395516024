import { loginRequest } from 'src/config';
import { callMsGraph, callMsGraphGetUserPhoto, callMsGraphGroups } from 'src/graph';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'src/config';

const instance = new PublicClientApplication(msalConfig);

export async function getUserInfo() {
  var user;
  await instance
    .acquireTokenSilent({
      ...loginRequest,
      account: instance.getAllAccounts()[0],
    })
    .then(async (response) => {
      await callMsGraph(response.accessToken).then((response) => (user = response));
      await callMsGraphGetUserPhoto(response.accessToken).then((response) => {
        user.avatar = response;
      });

      await callMsGraphGroups(response.accessToken).then(
        (response) => (user.groups = response.value.map((group) => group.displayName))
      );
      if (localStorage.getItem('auth') !== response.accessToken) {
        localStorage.setItem('auth', response.accessToken);
      }
    });
  return user;
}

export async function saveFreshAccessTokenInLocalStorage() {
  await instance
    .acquireTokenSilent({
      ...loginRequest,
      account: instance.getAllAccounts()[0],
    })
    .then(async (response) => {
      localStorage.setItem('auth', response.accessToken);
    });
}
